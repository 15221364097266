<template>
  <section>
    <p>NOSSA <strong>GALERIA</strong></p>
  </section>
  <section>
    <ul class="g2">
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box03.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box03.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box03.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box03.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box03.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box01.jpeg" width="0" height="0" alt=""></li>
      <li><img src="@/assets/box02.jpeg" width="0" height="0" alt=""></li>
    </ul>
  </section>
</template>
<style lang="scss" scoped>
p{
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 35px;
    font-weight: 400;
}
img{
  max-width: 100%!important;
  min-width: 100%!important;
  width:auto ;
  height: auto;
}
.g2{
  column-gap: 5px;
}
</style>
